import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { MarkdownCard } from "../assemblies/card"
import {
  PageContainer,
  PageMain,
  PageSidebar,
  PageFooter,
  PageOuterSidebar,
} from "../assemblies/pageTemplate"
import { Stack } from "../layout/stack"
import { PageProps, TagContext } from "../types"
import { PageHeader, PageTitle, PageMeta } from "../assemblies/pageHeader"
import { tagListing, tagFormat } from "../elements/tag"
import { SecondaryNav } from "../assemblies/nav"

const TagTemplate: React.FC<PageProps<
  GatsbyTypes.TagPageQuery,
  TagContext
>> = ({
  data: { allMarkdownRemark, tagsGroup },
  pageContext: { tag, seriesInfo },
  path,
}) => {
  const tagSiblings = tagListing(tagsGroup.group)

  return (
    <PageContainer>
      <PageOuterSidebar>
        <PageSidebar>
          <SecondaryNav parents={[]} siblings={tagSiblings} activePath={path} />
        </PageSidebar>
        <PageMain>
          <PageHeader>
            <PageTitle title={tagFormat(tag)} />
            <PageMeta description={`Site content related to ${tag}`} />
          </PageHeader>
          <Stack gap={1}>
            {allMarkdownRemark.edges.map(({ node }) => (
              <MarkdownCard
                remark={node}
                key={node.id}
                seriesInfo={
                  node.frontmatter?.path
                    ? seriesInfo[node.frontmatter.path]
                    : undefined
                }
              />
            ))}
          </Stack>
        </PageMain>
      </PageOuterSidebar>
      <PageFooter />
    </PageContainer>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          ...MarkdownNode
        }
      }
    }
    tagsGroup: allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
